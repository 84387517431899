<template>
  <b-modal
      v-model="settingModal"
      title="제어기 관리"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
  >
    <div class="popup-farm-setting">
      <div class="card card-custom card-stretch gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 align-items-center">
          <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            {{ connectNm }}
          </span>
          </h3>
          <div class="card-toolbar toolbar-symbol">
            <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="settingModal=false">
              <i class="la la-close font-size-h5"></i>
            </b-button>
          </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-0">
          <Draggable class="setting-list row" handle=".draggable-handle" v-bind="dragOptions">
            <div v-show="cropEnvType !== 'Type B'" class="col-12 col-lg-6 col-xl-4">
              <Setting1
                  :element-obj="temperature"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting1>
            </div>
            <div v-show="cropEnvType === 'Type B'" class="col-12 col-lg-6 col-xl-4">
              <Setting11
                  :element-obj="temperature"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting11>
            </div>
            <div v-show="cropEnvType !== 'Type B'" class="col-12 col-lg-6 col-xl-4">
              <Setting2
                  :element-obj="humidity"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting2>
            </div>
            <div v-show="cropEnvType === 'Type B'" class="col-12 col-lg-6 col-xl-4">
              <Setting12
                  :element-obj="humidity"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting12>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <Setting3
                  :element-obj="co2"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting3>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <Setting4
                  :element-obj="ph"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting4>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <Setting5
                  :element-obj="ec"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting5>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
              <Setting6
                  :element-obj="led"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting6>
            </div>
            <div v-show="controllerType !== '엠코피아'" class="col-12 col-lg-6 col-xl-4">
              <Setting7
                  :element-obj="fan"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting7>
            </div>
            <div v-show="controllerType !== '엠코피아' && controllerType !== '코모로'" class="col-12 col-lg-6 col-xl-4">
              <Setting9
                  :element-obj="airfan"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting9>
            </div>
            <div v-show="controllerType !== '엠코피아'" v-for="(value, key) in pump" v-bind:key="key" class="col-12 col-lg-6 col-xl-4">
              <Setting8
                  :element-obj="value"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting8>
            </div>
            <div v-show="controllerType ==='제닉스'" class="col-12 col-lg-6 col-xl-4">
              <Setting10
                  :element-obj="uv"
                  :mutate-value="mutateValue"
                  :effect-value="effectValue"
                  :controller-type="controllerType"
              ></Setting10>
            </div>
          </Draggable>
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary btn-lg" v-bind:class="{'spinner spinner-light spinner-right': loaded}" @click="insertSetting()"><span>{{ i18n.edit }}</span></button>
          <button type="button" class="btn ml-3 btn-secondary btn-lg" @click="settingModal=false"><span>{{ i18n.close }}</span></button>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </b-modal>
</template>

<script>
import Draggable from 'vuedraggable';
import Setting1 from '@/view/content/popup/Setting1.vue';
import Setting2 from '@/view/content/popup/Setting2.vue';
import Setting3 from '@/view/content/popup/Setting3.vue';
import Setting4 from '@/view/content/popup/Setting4.vue';
import Setting5 from '@/view/content/popup/Setting5.vue';
import Setting6 from '@/view/content/popup/Setting6.vue';
import Setting7 from '@/view/content/popup/Setting7.vue';
import Setting8 from '@/view/content/popup/Setting8.vue';
import Setting9 from '@/view/content/popup/Setting9.vue';
import Setting10 from '@/view/content/popup/Setting10.vue';
import Setting11 from '@/view/content/popup/Setting11.vue';
import Setting12 from '@/view/content/popup/Setting12.vue';
import {ACT_GET_CONNECT_SET, ACT_INSERT_CONNECT_SET, ACT_GET_LAST_STATUS_LOG} from '@/core/services/variable'; // ACT_GET_STATUS_LOGS_STATICS
import {getItem, getResult, isSuccess, lengthCheck, syncObj2, syncObj3} from '@/core/services/funcs'; // getItems
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "SettingModal",
  components: {
    Draggable,
    Setting1,
    Setting2,
    Setting3,
    Setting4,
    Setting5,
    Setting6,
    Setting7,
    Setting8,
    Setting9,
    Setting10,
    Setting11,
    Setting12
  },
  props: {
    target: Number,
    systemId: Number,
    connectNm: String,
    controllerType: String,
    cropEnvType: String,
  },
  watch: {
    target(){
      this.settingModal = true;
      this.loaded = false;

      Promise.all([
        this.$store.dispatch(ACT_GET_CONNECT_SET, this.systemId),
        // this.$store.dispatch(ACT_GET_STATUS_LOGS_STATICS, {systemId: this.systemId, dateCd: 'h', pageNo: 1, pageSize: 1000}),
        this.$store.dispatch(ACT_GET_LAST_STATUS_LOG, this.systemId)
      ]).then(([resp,/*logs,*/ lastLog]) => {
        if(lengthCheck(lastLog)) {
            // const statusLogs = getItems(logs);
            // const lateStatusLog = statusLogs[statusLogs.length - 1];
            const lateStatusLog = getItem(lastLog)
            this.temperature.currentValue = lateStatusLog.tempValue/100;
            this.humidity.currentValue = lateStatusLog.humidityValue/100
            this.co2.currentValue = lateStatusLog.co2Value
            this.ph.currentValue = lateStatusLog.phValue/100
            this.ec.currentValue = lateStatusLog.ecValue/1000
          }

        if(lengthCheck(resp)){
          const item = getItem(resp);
          this.elements.forEach(element => {
            syncObj2(this[element], item);
          });
          this.pump = this.refinePumpDataForUI(item)
        } else {
          this.clearSetting();
        }

        this.effectValue++;
      }).catch(e => {
        console.error(e);
        this.clearSetting()
        this.effectValue++;
      });
    },
    effectValue(){
      this.temperature.tempUsed = [];
      this.temperature.tempStarttimeHm = [];
      this.temperature.tempEndtimeHm = [];
      this.temperature.tempValues = [];
      this.temperature.tempDiffs = [];
      this.humidity.humUsed = [];
      this.humidity.humStarttimeHm = [];
      this.humidity.humEndtimeHm = [];
      this.humidity.humValues = [];
      this.humidity.humDiffs = [];
      
      if(this.temperature.temp) {
        const tempTemp = JSON.parse(this.temperature.temp);
        const lengthCheck = tempTemp.length;

        for (let i = 0; i < Math.min(lengthCheck, 6); i++) {
          this.temperature.tempUsed[i] = tempTemp[i].check === 1 ? true : false;
          this.temperature.tempStarttimeHm[i] = tempTemp[i].startTime;
          this.temperature.tempEndtimeHm[i] = tempTemp[i].endTime;
          this.temperature.tempValues[i] = parseInt(tempTemp[i].target)/100;
          this.temperature.tempDiffs[i] = parseInt(tempTemp[i].diff)/100;
        }
      }

      if(this.humidity.humidity) {
        const tempHum = JSON.parse(this.humidity.humidity);
        const lengthCheck = tempHum.length;

        for (let i = 0; i < Math.min(lengthCheck, 6); i++) {
          this.humidity.humUsed[i] = tempHum[i].check === 1 ? true : false;
          this.humidity.humStarttimeHm[i] = tempHum[i].startTime;
          this.humidity.humEndtimeHm[i] = tempHum[i].endTime;
          this.humidity.humValues[i] = parseInt(tempHum[i].target)/100;
          this.humidity.humDiffs[i] = parseInt(tempHum[i].diff)/100;
        }
      }
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  data() {
    return {
      settingModal: false,
      loaded: false,
      effectValue: 0,
      elements: ['temperature', 'humidity', 'co2', 'ec', 'ph', 'led', 'fan', 'airfan', 'pump', 'mainpump','uv'],
      temperature: {
        currentValue: 0,
        temp:'',
        tempUsed: [],
        tempStarttimeHm: [],
        tempEndtimeHm: [],
        tempValues: [],
        tempDiffs: [],
        tempValue : 0,
        tempLimithigh : 0,
        tempLimitlow : 0,
        tempMin : 0,
        tempMax : 0,
        tempnLimithigh : 0,
        tempnLimitlow : 0,
        tempnMin : 0,
        tempnMax : 0,
      },
      humidity: {
        currentValue: 0,
        humidity: '',
        humUsed: [],
        humStarttimeHm: [],
        humEndtimeHm: [],
        humValues: [],
        humDiffs: [],
        humidityValue: 0,
        humidityLimithigh: 0,
        humidityLimitlow: 0,
        humidityMin: 0,
        humidityMax: 0,
        humiditynLimithigh: 0,
        humiditynLimitlow: 0,
        humiditynMin: 0,
        humiditynMax: 0
      },
      co2: {
        currentValue: 0,
        co2Value : 0,
        co2Diff : 0,
        co2Limithigh : 0,
        co2Limitlow : 0,
        co2Interval : 0,
        co2Duration : 0,
        co2StarttimeHh : 0,
        co2StarttimeMm : 0,
        co2DurationHh : 0,
        co2Min: 0,
        co2Max: 0,
        co2Count: 0,
      },
      ec: {
        currentValue: 0,
        ecValue : 0,
        ecDiff : 0,
        ecLimithigh : 0,
        ecLimitlow : 0,
        ecInterval : 0,
        ecDuration : 0,
        ecMin: 0,
        ecMax: 0,
        ecCount: 0
      },
      ph: {
        currentValue: 0,
        phValue : 0,
        phDiff : 0,
        phLimithigh : 0,
        phLimitlow : 0,
        phInterval : 0,
        phDuration : 0,
        phMin : 0,
        phMax : 0,
        phCount: 0
      },
      led: {
        ledStarttimeHh: '',
        ledStarttimeMm: '',
        ledDurationHh: 0
      },
      fan: {
        fan: 0,
        fanStarttimeHh: '',
        fanStarttimeMm: '',
        fanDurationHh: 0,
        fanInterval: 0,
        fanDuration: 0,
      },
      airfan: {
        airfan: 0,
        airfanStarttimeHh: '',
        airfanStarttimeMm: '',
        airfanDurationHh: 0,
        airfanInterval: 0,
        airfanDuration: 0,
      },
      pump: {
        pump1: {
          key: 'pump1',
          name: '펌프 1',
          state: 0,
          starttimeHh: '',
          starttimeMm: '',
          durationtimeHh: '',
          durationtimeMm: '',
          interval: '',
          duration:''
        },
      },
      mainpump: {
        mainpumpStarttimeHh: '',
        mainpumpStarttimeMm: '',
        mainpumpDurationHh: '',
        mainpumpInterval : '',
        mainpumpDuration : '',
      },
      uv: {
        uvStarttimeHh: '',
        uvStarttimeMm: '',
        uvDurationHh: 0
      },
      i18n: {
        edit: i18n.t('cultivationEnvControlSetting.edit'),
        close: i18n.t('cultivationEnvControlSetting.close'),
        pump: i18n.t('cultivationEnvControlSetting.pump'),
        settingEditted: i18n.t('cultivationEnvControlSetting.settingEditted'),
        settingEditConfirm: i18n.t('cultivationEnvControlSetting.settingEditConfirm'),
      }
    };
  },
  methods: {
    insertSetting() {
      if(this.loaded) return;

      this.loaded = true

      const params = {
        systemId: this.systemId,
      };
      this.elements.forEach(element => {
        syncObj3(this[element], params);
      });
      if(!params.ledStarttimeHh) params.ledStarttimeHh = '00';
      if(!params.ledStarttimeMm) params.ledStarttimeMm = '00';
      if(!params.fanStarttimeHh) params.fanStarttimeHh = '00';
      if(!params.fanStarttimeMm) params.fanStarttimeMm = '00';
      if(!params.airfanStarttimeHh) params.airfanStarttimeHh = '00';
      if(!params.airfanStarttimeMm) params.airfanStarttimeMm = '00';
      if(!params.uvStarttimeHh) params.uvStarttimeHh = '00';
      if(!params.uvStarttimeMm) params.uvStarttimeMm = '00';
      params.mainpump = this.refinePumpDataForDB(this.pump, 'state')
      params.mainpumpStarttimeHh = this.refinePumpDataForDB(this.pump, 'starttimeHh')
      params.mainpumpStarttimeMm = this.refinePumpDataForDB(this.pump, 'starttimeMm')
      params.mainpumpDurationHh = this.refinePumpDataForDB(this.pump, 'durationtimeHh')
      params.mainpumpDurationMm = this.refinePumpDataForDB(this.pump, 'durationtimeMm')
      params.mainpumpInterval = this.refinePumpDataForDB(this.pump, 'interval')
      params.mainpumpDuration = this.refinePumpDataForDB(this.pump, 'duration')

      // LED정보 문자열화
      var ledStarttimeHm = '';
      var ledEndtimeHm = '';
      var tempTime = ''
      var ledEndtimeHh = parseInt(params.ledStarttimeHh) + parseInt(params.ledDurationHh);

      if(ledEndtimeHh >= 24) ledEndtimeHh -= 24;
      params.ledStarttimeHh = `${params.ledStarttimeHh}`;
      params.ledDurationHh = `${params.ledDurationHh}`;
      ledEndtimeHh = `${ledEndtimeHh}`;
      if(params.ledStarttimeHh.length == 1) params.ledStarttimeHh = '0' + params.ledStarttimeHh;
      if(ledEndtimeHh.length == 1) ledEndtimeHh = '0' + ledEndtimeHh;
      ledStarttimeHm = params.ledStarttimeHh + ':' + params.ledStarttimeMm;
      ledEndtimeHm = ledEndtimeHh + ':' + params.ledStarttimeMm;

      // 온습도 JSON
      let newTemp = [];
      let newHum = [];

      if(this.cropEnvType === 'Type A') {
        for (let i = 0; i < 2; i++){
          if (i == 1){
            tempTime = ledStarttimeHm
            ledStarttimeHm = ledEndtimeHm
            ledEndtimeHm = tempTime
          }
          newTemp[i] = {
            check: 1,
            startTime: ledStarttimeHm,
            endTime: ledEndtimeHm,
            target: (params.tempValues[i] ? (params.tempValues[i]*100).toFixed(0) : 0),
            diff: (params.tempDiffs[i] ? (params.tempDiffs[i]*100).toFixed(0) : 0)
          }
          newHum[i] = {
            check: 1,
            startTime: ledStarttimeHm,
            endTime: ledEndtimeHm,
            target: (params.humValues[i] ? (params.humValues[i]*100).toFixed(0) : 0),
            diff: (params.humDiffs[i] ? (params.humDiffs[i]*100).toFixed(0) : 0)
          }
        }

        for (let i = 2; i < 6; i++){
          newTemp[i] = {
            check: 0,
            startTime: "00:00",
            endTime: "00:00",
            target: "0",
            diff: "0"
          }
          newHum[i] = {
            check: 0,
            startTime: "00:00",
            endTime: "00:00",
            target: "0",
            diff: "0"
          }          
        }
        
        params.tempValue = params.tempValues[0]
        params.tempnValue = params.tempValues[1]
        params.tempDiff = params.tempDiffs[0]
        params.tempnDiff = params.tempDiffs[1]
        params.humidityValue = params.humValues[0]
        params.humiditynValue = params.humValues[1]
        params.humidityDiff = params.humDiffs[0]
        params.humiditynDiff = params.humDiffs[1]

      } else if (this.cropEnvType === 'Type B') { 
        for (let i = 0; i < params.tempUsed.length; i++){
          newTemp[i] = {
            check: (params.tempUsed[i] ? 1 : 0),
            startTime: (params.tempStarttimeHm[i] ? params.tempStarttimeHm[i].substring(0, 5) : undefined),
            endTime: (params.tempEndtimeHm[i] ? params.tempEndtimeHm[i].substring(0, 5) : undefined),
            target: (params.tempValues[i] ? (params.tempValues[i]*100).toFixed(0) : 0),
            diff: (params.tempDiffs[i] ? (params.tempDiffs[i]*100).toFixed(0) : 0)
          }
        }
        for (let i = 0; i < params.humUsed.length; i++){
          newHum[i] = {
            check: (params.humUsed[i] ? 1 : 0),
            startTime: (params.humStarttimeHm[i] ? params.humStarttimeHm[i].substring(0, 5) : undefined),
            endTime: (params.humEndtimeHm[i] ? params.humEndtimeHm[i].substring(0, 5) : undefined),
            target: (params.humValues[i] ? (params.humValues[i]*100).toFixed(0) : 0),
            diff: (params.humDiffs[i] ? (params.humDiffs[i]*100).toFixed(0) : 0)
          }
        }
      }
      params.temp = JSON.stringify(newTemp)
      params.humidity = JSON.stringify(newHum)

      const yesCallback = () => {
        this.$store.dispatch(ACT_INSERT_CONNECT_SET, params).then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.connectNm + this.i18n.settingEditted, color: 'success'});
            this.settingModal = false
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
          }
          this.loaded = false
        }).catch(e => {
          console.error(e);
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.cultivationCropMangement.cropAddError')});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.connectNm + this.i18n.settingEditConfirm},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });
    },
    clearSetting(){
      this.temperature = {
        temp: '',
        tempUsed: [],
        tempStarttimeHm: [],
        tempEndtimeHm: [],
        tempValues: [],
        tempDiffs: [],
        tempValue : 0,
        tempLimithigh : 0,
        tempLimitlow : 0,
        tempMin : 0,
        tempMax : 0,
        tempnLimithigh : 0,
        tempnLimitlow : 0,
        tempnMin : 0,
        tempnMax : 0,
      };
      this.humidity = {
        humidity: '',
        humUsed: [],
        humStarttimeHm: [],
        humEndtimeHm: [],
        humValues: [],
        humDiffs: [],
        humidityValue: 0,
        humidityLimithigh: 0,
        humidityLimitlow: 0,
        humidityMin: 0,
        humidityMax: 0,
        humiditynLimithigh: 0,
        humiditynimitlow: 0,
        humiditynMin: 0,
        humiditynMax: 0
      };
      this.co2 = {
        co2Value : 0,
        co2Diff : 0,
        co2Limithigh : 0,
        co2Limitlow : 0,
        co2Interval : 0,
        co2Duration : 0,
        co2StarttimeHh : 0,
        co2StarttimeMm : 0,
        co2DurationHh : 0,
        co2Min: 0,
        co2Max: 0,
        co2Count: 0,
      };
      this.ec = {
        ecValue : 0,
        ecDiff : 0,
        ecLimithigh : 0,
        ecLimitlow : 0,
        ecInterval : 0,
        ecDuration : 0,
        ecMin: 0,
        ecMax: 0,
        ecCount: 0
      };
      this.ph =  {
        phValue : 0,
        phDiff : 0,
        phLimithigh : 0,
        phLimitlow : 0,
        phInterval : 0,
        phDuration : 0,
        phMin : 0,
        phMax : 0,
        phCount: 0
      };
      this.led = {
        ledStarttimeHh: '',
        ledStarttimeMm: '',
        ledDurationHh: 0
      };
      this.fan = {
        fan: 0,
        fanStarttimeHh: '',
        fanStarttimeMm: '',
        fanDurationHh: 0,
        fanInterval: 0,
        fanDuration: 0,
      };
      this.airfan = {
        airfan: 0,
        airfanStarttimeHh: '',
        airfanStarttimeMm: '',
        airfanDurationHh: 0,
        airfanInterval: 0,
        airfanDuration: 0,
      };
      this.pump = {
        pump1: {
          key: 'pump1',
          name: `${i18n.t('cultivationEnvControlSetting.pump')}`,
          state: 0,
          starttimeHh: '',
          starttimeMm: '',
          durationtimeHh: '',
          durationtimeMm: '',
          interval : 0,
          duration : 0,
        },
      };
      this.uv = {
        uvStarttimeHh: '',
        uvStarttimeMm: '',
        uvDurationHh: 0
      };
    },
    mutateValue(pKey, cKey, value){
      if(this[pKey] && this[pKey][cKey] !== undefined) this[pKey][cKey] = value;
    },

    refinePumpDataForUI(item) {
      var pumps

      if (item.mainpump != null){
        pumps = item.mainpump.split(',')
      }else {
        return 0
      }

      const pump = pumps.reduce((acc, val, index) => {
        acc[`pump${index + 1}`] = {
          key: `pump${index + 1}`,
          name: `${this.i18n.pump} ${index + 1}`,
          state: Number(val)
        }

        if (item.mainpumpStarttimeHh != null)
          acc[`pump${index + 1}`].starttimeHh = item.mainpumpStarttimeHh.split(',')[index]
        if (item.mainpumpStarttimeMm != null)
          acc[`pump${index + 1}`].starttimeMm = item.mainpumpStarttimeMm.split(',')[index]
        if (item.mainpumpDurationHh != null)
          acc[`pump${index + 1}`].durationtimeHh = item.mainpumpDurationHh.split(',')[index]
        if (item.mainpumpDurationMm != null)
          acc[`pump${index + 1}`].durationtimeMm = item.mainpumpDurationMm.split(',')[index]
        if (item.mainpumpDuration != null)
          acc[`pump${index + 1}`].duration = item.mainpumpDuration.split(',')[index]
        else
          acc[`pump${index + 1}`].duration = 0
        if (item.mainpumpInterval != null){
          acc[`pump${index + 1}`].interval = item.mainpumpInterval.split(',')[index]
        } else
          acc[`pump${index + 1}`].interval = 0

        return acc
      }, {});

      return pump
    },
    refinePumpDataForDB(pump, prop){
      const mainpump = Object.entries(pump).reduce((acc, val, index) => {
        if (index > 0) {
          acc += ','
        }
        acc += (String(val[1][prop]).trim() === null || String(val[1][prop]).trim() === 'undefined')? '00': String(val[1][prop]).trim()
        return acc
      }, '')
      return mainpump
    }
  }
};
</script>
<style>
.genix .b-form-spinbutton.form-control.d-inline-flex.flex-column.b-time-minutes {
    background: #e4e6ef;
}
</style>