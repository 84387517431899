import { render, staticRenderFns } from "./Setting9.vue?vue&type=template&id=273c4ce4&"
import script from "./Setting9.vue?vue&type=script&lang=js&"
export * from "./Setting9.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports