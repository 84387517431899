<template>
  <div class="page-farm-setting">
    <div class="card card-custom card-stretch gutter-b farm-crops-conatiner">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header></t-farmon-table-header>
      </div>
      <div class="card-body pt-0">
        <div class="crops-table">
          <v-data-table
              :headers="fields"
              :items="items"
              class="elevation-1 farm-data-table"
              no-data-text="No Data"
              hide-default-footer
          >
            <template v-slot:[`item.fieldH`]="{item}">
              <b-button size="sm" variant="dark" class="rounded-sm" @click="changeSetting( item['fieldA'], item['systemId'], item['fieldC'], item['fieldD'] )">
                <span class="svg-icon svg-icon-white mr-1">
                  <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/General/Settings-2.svg" />
                </span>
                <span class="text font-weight-normal">{{ i18n.setting }}</span>
              </b-button>
            </template>
          </v-data-table>

        </div>
      </div>
    </div>

    <setting-modal
        :target="target"
        :system-id="systemId"
        :connect-nm="connectNm"
        :controller-type="controllerType"
        :crop-env-type="cropEnvType"
    >
    </setting-modal>

  </div>
</template>
<style>

</style>
<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_GET_MY_CONNECTS} from '@/core/services/variable';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';
import SettingModal from '@/components/modal/SettingModal';
import {collectionsCheck, getItem, lengthCheck} from '@/core/services/funcs';
import TFarmonTableHeader from '@/components/TFarmonTableHeader';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "FarmSetting",
  components: {
    SettingModal,
    TFarmonTableHeader
  },
  beforeMount() {
    this.$store.dispatch(ACT_GET_MY_CONNECTS).then(resp => {
      if(lengthCheck(resp)){
        const item = getItem(resp)
        if(collectionsCheck(item.farmConnects, 'seq')){
          item.farmConnects.forEach((connect, idx) => {
            if(connect.internetConnectYn == 'Y'){
              this.items.push({
                'idx': (idx + 1), 'fieldA' : connect.connectNm, 'fieldB' : connect.connectCd, 'fieldC' : connect.envCtrlType, 'fieldD': connect.cropEnvType,
                'fieldE' : connect.growType, 'fieldF': connect.internetConnectYn, 'fieldG': connect.systemId, 
                'systemId': connect.systemId
              });
            }
          });
        }
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('topNavBar.farm.info'), icon: 'fas fa-clipboard' }, { title: i18n.t('topNavBar.farm.cultivationEnvControl') }]);
    window.scrollTo(0,0);
  },
  data() {
    return {
      fields: [
        { value: 'idx', text: i18n.t('farm.controller.numbering'), width: '80px'},
        { value: 'fieldA', text: i18n.t('farm.controller.controllerName')},
        { value: 'fieldB', text: i18n.t('farm.controller.controllerCode')},
        { value: 'fieldC', text: i18n.t('farm.controller.controllerType')},
        { value: 'fieldD', text: i18n.t('farm.controller.cropEnvType')},
        { value: 'fieldE', text: i18n.t('farm.controller.culturalType')},
        { value: 'fieldF', text: i18n.t('farm.controller.isInternetConnected')},
        { value: 'fieldG', text: i18n.t('farm.controller.systemId')},
        { value: 'fieldH', text: '', width: '80px'},        
      ],
      items: [],
      target: 0,
      systemId: 0,
      connectNm: '',
      controllerType: '',
      cropEnvType: '',
      i18n: {
        setting: i18n.t('farm.controller.setting'),
        systemIdNotSet: i18n.t('farm.controller.systemIdNotSet')
      },
    }
  },
  methods: {
    changeSetting(connectNm, systemId, controllerType, cropEnvType){
      this.connectNm = connectNm;
      this.systemId = systemId;
      this.controllerType = controllerType;
      this.cropEnvType = cropEnvType;
      if(this.systemId){
        this.target++;
      }else{
        this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.systemIdNotSet});
      }
    }
  }
};
</script>
