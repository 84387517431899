<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start">
        <span class="title font-weight-bold">
          {{ i18n.humidity }}
        </span>
        <span v-bind:style="isOperatingCorrectly" class="ml-5">{{ elementObj.currentValue }}% (now)</span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div v-if="this.controllerType !== '제닉스' " class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.target }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton id="sb-01" v-model="humidityValue" min="0" max="100" size="sm" @change="conveyData('humidityValue')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->

      <div v-if="this.controllerType == '엠코피아'" class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.management }}</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.maxDay}}</div>
            <b-form-spinbutton v-model="humidityMax" min="0" max="100" size="sm" @change="conveyData('humidityMax')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.minNight}}</div>
            <b-form-spinbutton v-model="humidityMin" min="0" max="100" size="sm" @change="conveyData('humidityMin')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{humiditynMax}}%</div>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{humiditynMin}}%</div>
          </div>
        </b-card>
      </div>

      <div v-if="this.controllerType === '제닉스'" class="form-group row">
        <b-card v-for="(item, i) in humUsed" :key=i no-body class="col-12 col-lg-12 d-flex flex-wrap flex-row pt-0 pb-2 mb-2">          
          <div class="offset-4 col-1 pt-1 pl-3">
            <b-form-checkbox v-model="humUsed[i]" name="check-button" size="lg" @change="conveyTimeUse('humUsed[' + i + ']')"></b-form-checkbox>
          </div>
          <label class="col-form-label col-6 pl-3">
            <span class="font-weight-normal">{{i18n.time[i]}}</span>
          </label>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.startTime}}</div>
            <b-form-timepicker v-model="humStarttimeHm[i]" size="sm" locale="en-us" class="genix" minutes-step="60" @input="conveyTime('humStarttimeHm[' + i + ']')"></b-form-timepicker>
          </div>
          <div class="col-6 pl-2 pr-0 pb-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.endTime}}</div>
            <b-form-timepicker v-model="humEndtimeHm[i]" size="sm" locale="en-us" class="genix" minutes-step="60" @input="conveyTime('humEndtimeHm[' + i + ']')"></b-form-timepicker>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.target}}</div>
            <b-form-spinbutton v-model="humValues[i]" min="0" max="100" size="sm" @change="conveyData('humValues[' + i + ']')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.diff}}</div>
            <b-form-spinbutton v-model="humDiffs[i]" min="0" max="50" size="sm" @change="conveyData('humDiffs[' + i + ']')"></b-form-spinbutton>
          </div>        
        </b-card>       
      </div>

      <div v-if="this.controllerType !== '제닉스' " class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.abnormalalert }}</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.maxDay}}</div>
            <b-form-spinbutton v-model="humidityLimithigh" min="0" max="100" size="sm" @change="conveyData('humidityLimithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.minNight}}</div>
            <b-form-spinbutton v-model="humidityLimitlow" min="0" max="100" size="sm" @change="conveyData('humidityLimitlow')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{humiditynLimithigh}}%</div>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{humiditynLimitlow}}%</div>
          </div>
        </b-card>
      </div>
      <!--end::form-group-->
    </div>
    <!--end::Body-->
  </div>
</template>
<script>
import {syncObj3} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting12",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) syncObj3(this.elementObj, this);      
    }
  },
  data() {
    return {
      humidity: '',
      humidityValue: 0,      
      humUsed: [],
      humStarttimeHm: [],
      humEndtimeHm: [],
      humValues: [],
      humDiffs: [],
      humidityLimithigh: 0,
      humidityLimitlow: 0,
      humidityMin: 0,
      humidityMax: 0,
      humiditynLimithigh: 0,
      humiditynLimitlow: 0,
      humiditynMin: 0,
      humiditynMax: 0,
      i18n: {
        management: i18n.t('cultivationEnvControlSetting.management'),
        abnormalalert: i18n.t('cultivationEnvControlSetting.abnormalalert'),
        diff: i18n.t('cultivationEnvControlSetting.diff'),
        humidity: i18n.t('cultivationEnvControlSetting.humidity'),
        target: i18n.t('cultivationEnvControlSetting.target'),
        targetDay: i18n.t('cultivationEnvControlSetting.targetDay'),
        targetNight: i18n.t('cultivationEnvControlSetting.targetNight'),
        startTime: i18n.t('cultivationEnvControlSetting.startTime'),
        endTime: i18n.t('cultivationEnvControlSetting.endTime'),
        min: i18n.t('cultivationEnvControlSetting.min'),
        max: i18n.t('cultivationEnvControlSetting.max'),
        limitHigh: i18n.t('cultivationEnvControlSetting.limitHigh'),
        maxDay: i18n.t('cultivationEnvControlSetting.maxDay'),
        minNight: i18n.t('cultivationEnvControlSetting.minNight'),
        time: i18n.t('cultivationEnvControlSetting.time')
      }
    };
  },
  computed: {
    isOperatingCorrectly() {
      if (this.elementObj.currentValue > this.humidityMax || this.elementObj.currentValue < this.humidityMin) {
        return {
          color: 'red'
        }
      }

      return {
        color: 'green'
      }
    },
  },
  methods: {
    conveyTimeUse(key) {
      var nkey = key.slice(-2, -1);
      this.mutateValue('humidity', key, this.humUsed[nkey]);
    },
    conveyTime(key) {
      var nkey = key.slice(-2, -1);
      var prop = key.slice(0, -3);
      var newValue = this[prop][nkey].split(":").slice(0,2).join(":")
      this.mutateValue('humidity', key, newValue);
    },
    conveyData(key){
      this.mutateValue('humidity', key, this[key]);
      var nkey = "";
      var prop = "";
      var mkey = "";

      if (key == "humidityMax"){
        nkey =  "humiditynMax";
        this[nkey] = this[key]+10
      } else if (key == "humidityMin") {
        nkey =  "humiditynMin";
        this[nkey] = this[key]+10
      } else if (key == "humidityLimithigh"){
        nkey =  "humiditynLimithigh";
        this[nkey] = this[key]+10
      } else if (key == "humidityLimitlow"){
        nkey =  "humiditynLimitlow";
        this[nkey] = this[key]+10
      } else if (key.slice(0,-3) == "humValues"){
        nkey = key
        prop = key.slice(0,-3);
        mkey = key.slice(-2,-1);
        this[nkey] = this[prop][mkey]
      } else if (key.slice(0,-3) == "humDiffs"){
        nkey = key
        prop = key.slice(0,-3);
        mkey = key.slice(-2,-1);
        this[nkey] = this[prop][mkey]
      }
      
      this.mutateValue('humidity', nkey, this[nkey]);
    }
  }
};
</script>
