<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{ i18n.airfan }}
        </span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="#" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.startTime }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-timepicker v-if="this.controllerType == '제닉스'" v-model="airfanStarttimeHm" size="sm" locale="en-us" class="mb-2 genix" minutes-step="60" @input="conveyTime"></b-form-timepicker>
          <b-form-timepicker v-else v-model="airfanStarttimeHm" size="sm" locale="en-us" class="mb-2" @input="conveyTime"></b-form-timepicker>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div class="form-group row">
        <label class="col-form-label col-12">
          <span class="font-weight-normal">{{ i18n.operationTime }} : {{airfanDurationHh}}{{i18n.hour}}</span>
        </label>
        <div class="col-12">
          <template>
            <div class="custom-range-slider">
              <b-form-input id="range-2" v-model="airfanDurationHh" type="range" min="0" max="24" step="1" @change="conveyData('airfanDurationHh')"></b-form-input>
              <div class=" d-flex justify-content-between">
                <span class="text">0</span>
                <span class="text">3</span>
                <span class="text">6</span>
                <span class="text">9</span>
                <span class="text">12</span>
                <span class="text">15</span>
                <span class="text">18</span>
                <span class="text">21</span>
                <span class="text">24</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <!-- <div v-if="this.controllerType == '제닉스'">
        <div class="form-group row">
          <label class="col-form-label col-12 col-lg-6">
            <span class="font-weight-normal">{{ i18n.interval }}</span>
          </label>
          <div class="col-12 col-lg-6">
            <b-form-spinbutton v-model="airfanInterval" min="0" max="10000" size="sm" step="1" @change="conveyData('airfanInterval')"></b-form-spinbutton>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-12 col-lg-6">
            <span class="font-weight-normal">{{ i18n.duration }}</span>
          </label>
          <div class="col-12 col-lg-6">
            <b-form-spinbutton v-model="airfanDuration" min="0" max="10000" size="sm" step="1" @change="conveyData('airfanDuration')"></b-form-spinbutton>
          </div>
        </div>
      </div> -->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import {syncObj3} from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting9",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) {
        syncObj3(this.elementObj, this)
        this.airfanDurationHh = this.elementObj.airfanDurationHh
        if(this.elementObj.airfanStarttimeHh !== undefined || this.elementObj.airfanStarttimeMm !== undefined) {
          this.airfanStarttimeHm = [(this.elementObj.airfanStarttimeHh || '00'), (this.elementObj.airfanStarttimeMm || '00'), '00'].join(':');
        }else{
          this.airfanStarttimeHm = '';
        }
      }
    }
  },
  data() {
    return {
      airfanStarttimeHm : '',
      airfanDurationHh : 0,
      airfanInterval : 0,
      airfanDuration : 0,
      i18n: {
        airfan: i18n.t('cultivationEnvControlSetting.airfan'),
        startTime: i18n.t('cultivationEnvControlSetting.startTime'),
        operationTime: i18n.t('cultivationEnvControlSetting.operationTime'),
        duration: i18n.t('cultivationEnvControlSetting.duration'),
        interval: i18n.t('cultivationEnvControlSetting.interval'),
        hour: i18n.t('cultivationEnvControlSetting.hour')
      }
    }
  },
  methods: {
    conveyTime(){
      if(this.airfanStarttimeHm){
        const starttimeList = this.airfanStarttimeHm.split(':');
        if(starttimeList && starttimeList.length === 3) {
          this.mutateValue('airfan', 'airfanStarttimeHh', starttimeList[0]);
          this.mutateValue('airfan', 'airfanStarttimeMm', starttimeList[1]);
        }
      }
    },
    conveyData(key){
      this.mutateValue('airfan', key, this[key]);
    }
  }
};
</script>
