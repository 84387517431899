<template>
  <div class="card card-custom card-stretch gutter-b setting-item">
    <!--begin::Header-->
    <div class="card-header align-items-center">
      <h3 class="card-title">
        <span class="title font-weight-bold">
          {{ i18n.temperature }}
        </span>
        <span v-bind:style="isOperatingCorrectly" class="ml-5">{{ elementObj.currentValue }}℃ (now)</span>
      </h3>
      <div class="card-toolbar toolbar-symbol">
        <a href="javascript:;" class="btn btn-icon btn-hover-light-primary draggable-handle">
          <i class="ki ki-menu"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body">
      <!--begin::form-group-->
      <div v-if="this.controllerType !== '제닉스' " class="form-group row">
        <label class="col-form-label col-12 col-lg-6">
          <span class="font-weight-normal">{{ i18n.target }}</span>
        </label>
        <div class="col-12 col-lg-6">
          <b-form-spinbutton v-model="tempValue" min="0" max="100" size="sm" @change="conveyData('tempValue')"></b-form-spinbutton>
        </div>
      </div>
      <!--end::form-group-->
      <!--begin::form-group-->
      <div v-if="this.controllerType == '엠코피아' " class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.management }}</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.maxDay}}</div>
            <b-form-spinbutton v-model="tempMax" min="0" max="100" size="sm" @change="conveyData('tempMax')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.minNight}}</div>
            <b-form-spinbutton v-model="tempMin" min="0" max="100" size="sm" @change="conveyData('tempMin')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnMax}}℃</div>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnMin}}℃</div>
          </div>
        </b-card>
      </div>

      <div v-if="this.controllerType === '제닉스'" class="form-group row">
        <b-card v-for="(item, i) in tempUsed" :key=i no-body class="col-12 col-lg-12 d-flex flex-wrap flex-row pt-0 pb-2 mb-2">          
          <div class="offset-4 col-1 pt-1 pl-3">
            <b-form-checkbox v-model="tempUsed[i]" name="check-button" size="lg" @change="conveyTimeUse('tempUsed[' + i + ']')"></b-form-checkbox>
          </div>
          <label class="col-form-label col-6 pl-3">
            <span class="font-weight-normal">{{i18n.time[i]}}</span>
          </label>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.startTime}}</div>
            <b-form-timepicker v-model="tempStarttimeHm[i]" size="sm" locale="en-us" class="genix" minutes-step="60" @input="conveyTime('tempStarttimeHm[' + i + ']')"></b-form-timepicker>
          </div>
          <div class="col-6 pl-2 pr-0 pb-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.endTime}}</div>
            <b-form-timepicker v-model="tempEndtimeHm[i]" size="sm" locale="en-us" class="genix" minutes-step="60" @input="conveyTime('tempEndtimeHm[' + i + ']')"></b-form-timepicker>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.target}}</div>
            <b-form-spinbutton v-model="tempValues[i]" min="0" max="40" size="sm" @change="conveyData('tempValues[' + i + ']')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.diff}}</div>
            <b-form-spinbutton v-model="tempDiffs[i]" min="0" max="20" size="sm" step="0.1" @change="conveyData('tempDiffs[' + i + ']')"></b-form-spinbutton>
          </div>        
        </b-card>       
      </div>

      <div v-if="this.controllerType !== '제닉스' " class="form-group row">
        <label class="col-form-label col-12 col-lg-2 align-self-center" style="margin-right: auto;">
          <span class="font-weight-normal">{{ i18n.abnormalalert }}</span>
        </label>
        <b-card no-body class="col-12 col-lg-9 d-flex flex-wrap flex-row pt-4 pb-4">
          <div class="col-6 pl-0 pr-2 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.maxDay}}</div>
            <b-form-spinbutton v-model="tempLimithigh" min="0" max="100" size="sm" @change="conveyData('tempLimithigh')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-2 pr-0 mb-5" style="display: flex; flex-direction: column; align-items: center">
            <div>{{i18n.minNight}}</div>
            <b-form-spinbutton v-model="tempLimitlow" min="0" max="100" size="sm" @change="conveyData('tempLimitlow')"></b-form-spinbutton>
          </div>
          <div class="col-6 pl-0 pr-2" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnLimithigh}}℃</div>
          </div>
          <div class="col-6 pl-2 pr-0" style="display: flex; flex-direction: column; align-items: center">
            <div>🌙 : {{tempnLimitlow}}℃</div>
          </div>
        </b-card>
      </div>
      <!--end::form-group-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import { syncObj3 } from '@/core/services/funcs';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "Setting11",
  props: {
    elementObj: Object,
    mutateValue: Function,
    effectValue: Number,
    controllerType: String
  },
  watch: {
    effectValue(){
      if(this.elementObj) syncObj3(this.elementObj, this);
    }
  },
  data() {
    return {
      temp: '',
      tempValue : 0,
      tempUsed: [],
      tempStarttimeHm: [],
      tempEndtimeHm: [],
      tempValues: [],
      tempDiffs: [],
      tempMin : 0,
      tempMax : 0,
      tempnMin : 0,
      tempnMax : 0,
      tempLimithigh : 0,
      tempLimitlow : 0,
      tempnLimithigh : 0,
      tempnLimitlow : 0,
      i18n: {
        management: i18n.t('cultivationEnvControlSetting.management'),
        abnormalalert: i18n.t('cultivationEnvControlSetting.abnormalalert'),
        diff: i18n.t('cultivationEnvControlSetting.diff'),
        temperature: i18n.t('cultivationEnvControlSetting.temperature'),
        target: i18n.t('cultivationEnvControlSetting.target'),
        targetDay: i18n.t('cultivationEnvControlSetting.targetDay'),
        targetNight: i18n.t('cultivationEnvControlSetting.targetNight'),
        startTime: i18n.t('cultivationEnvControlSetting.startTime'),
        endTime: i18n.t('cultivationEnvControlSetting.endTime'),
        min: i18n.t('cultivationEnvControlSetting.min'),
        max: i18n.t('cultivationEnvControlSetting.max'),
        limitHigh: i18n.t('cultivationEnvControlSetting.limitHigh'),
        maxDay: i18n.t('cultivationEnvControlSetting.maxDay'),
        minNight: i18n.t('cultivationEnvControlSetting.minNight'),
        time: i18n.t('cultivationEnvControlSetting.time')
      }
    };
  },
  computed: {
    isOperatingCorrectly() {
      if (this.elementObj.currentValue > this.tempMax || this.elementObj.currentValue < this.tempMin) {
        return {
          color: 'red'
        }
      }

      return {
        color: 'green'
      }
    },
  },
  methods: {
    conveyTimeUse(key) {
      var nkey = key.slice(-2, -1);
      // var value = this.tempUsed[nkey];
      // this.tempUsed[nkey] = value ? '1' : '0';
      this.mutateValue('temperature', key, this.tempUsed[nkey]);
    },
    conveyTime(key) {
      var nkey = key.slice(-2, -1);
      var prop = key.slice(0, -3);
      var newValue = this[prop][nkey].split(":").slice(0,2).join(":")
      this.mutateValue('temperature', key, newValue);
    },
    conveyData(key){
      this.mutateValue('temperature', key, this[key]);
      var nkey = "";      
      var prop = "";
      var mkey = "";

      if (key == "tempMax"){
        nkey =  "tempnMax";
        this[nkey] = this[key]-3
      } else if (key == "tempMin") {
        nkey =  "tempnMin";
        this[nkey] = this[key]-5
      } else if (key == "tempLimithigh"){
        nkey =  "tempnLimithigh";
        this[nkey] = this[key]-3
      } else if (key == "tempLimitlow"){
        nkey =  "tempnLimitlow";
        this[nkey] = this[key]-5
      } else if (key.slice(0,-3) == "tempValues"){
        nkey = key
        prop = key.slice(0,-3);
        mkey = key.slice(-2,-1);
        this[nkey] = this[prop][mkey]
      } else if (key.slice(0,-3) == "tempDiffs"){
        nkey = key
        prop = key.slice(0,-3);
        mkey = key.slice(-2,-1);
        this[nkey] = this[prop][mkey]
      }

      this.mutateValue('temperature', nkey, this[nkey]);
    }
  }
};
</script>
